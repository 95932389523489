import React from 'react';
import styled from 'styled-components';

import SEO from 'components/SEO';

import portraitMp4 from './videos/portrait.mp4';
import portraitWebm from './videos/portrait.webm';
import portraitPoster from './videos/portrait-poster.jpg';

const Video = styled.video`
  box-sizing: border-box;
  margin: 2em 0 2em 0;
  display: block;
  width: 100%;
  border: 0.0909em solid #b2b2b2
`;

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[]} />
    <Video
      controls
      width="640"
      poster={portraitPoster}
    >
      <source src={portraitWebm} type="video/webm" />
      <source src={portraitMp4} type="video/mp4" />
      Sorry, Ihr Browser unterstützt leider keine Videos.
    </Video>
    <h1>Fugenabdichtungsmassen</h1>

    <p>
… auf Polyurethan-, Silikon- und Acryl-Basis werden im Hoch- und Tiefbau
verwendet.
    </p>

    <p>
Trennungen von Bauteilen und Materialien erfordern eine Fugenabdichtung, welche
die Anforderungen von Dichtigkeit und Elastizität garantiert, sowie als
sichtbarer Arbeitsabschluss ihren Zweck erfüllt.
    </p>

    <h1>Anwendungsbereich im Hochbau</h1>

    <ul>
      <li>Beton-Dilationstfugen</li>
      <li>Fassadenfugen</li>
      <li>Fensteranschlüsse</li>
      <li>Nasszellen</li>
      <li>Bodenbeläge</li>
    </ul>

    <h1>Anwendungsbereich im Tiefbau</h1>

    <ul>
      <li>Brücken</li>
      <li>Stützmauern</li>
      <li>Betonplätze</li>
      <li>Kanäle &amp; Kläranlagen</li>
    </ul>

    <h1>Verarbeitung</h1>

    <p>
Eine wichtige Voraussetzung für hohe Qualität ist die Wahl des
richtigen Produktes. Saubere Untergrundvorbereitung und ein richtiges
Fugenhinterfüllprofil sind wichtige Elemente. Passende Voranstriche
(Primer) an den Fugenflanken garantieren, dass die Fuge nicht abreisst. Nachdem
die Fugenabdichtungsmasse eingebracht ist, wird sie mit einem Spachtel
abgezogen und geglättet.
    </p>

    <p>
Bei fachgerechter Ausführung und geeigneten Materialien ist eine gute Haftung
und eine hohe Bewgungsaufnahme garantiert.
    </p>
  </>
);

export default IndexPage;
